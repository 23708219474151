import styled from 'styled-components'

export const Wrapper = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
`
