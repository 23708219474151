import { createContext } from 'react'
import { chatBotData } from '../../data'

interface IChatMessage {
  text: string
  time: string
  sender: boolean
}

export interface IChatContext {
  '00001': IChatMessage[]
  '00002': IChatMessage[]
  '00003': IChatMessage[]
  '00004': IChatMessage[]
}

export const defaultContextValue: IChatContext = chatBotData

export const ChatContext = createContext(defaultContextValue)

ChatContext.displayName = 'ChatContext'
