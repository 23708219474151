import React, { memo, PropsWithChildren } from 'react'
import {
  NavigationItem,
  StyledLink,
  Title,
  Wrapper,
} from './navigation-item.styled'

interface INavigationItemProps {
  to: string
  icon: React.ReactElement
  selected: boolean
  onClick?: () => void
  disabled?: boolean
}

export const NavigationItemComponent = memo(
  ({
    to,
    icon,
    selected,
    onClick,
    children,
    disabled = false,
  }: PropsWithChildren<INavigationItemProps>) => (
    <NavigationItem selected={selected} onClick={onClick}>
      <StyledLink to={to} selected={selected} disabled={disabled}>
        <Wrapper selected={selected}>
          {icon}
          <Title>{children}</Title>
        </Wrapper>
      </StyledLink>
    </NavigationItem>
  )
)

NavigationItemComponent.displayName = 'NavigationItem'
