import styled from 'styled-components'

import { media } from '../../../styles'

export const MessagesContainer = styled.div`
  position: relative;
  height: calc(100% - 100px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  ${media.md`
      height: calc(100% - 80px);
  `}

  ${media.sm`
      height: calc(100% - 60px);
  `}
`

export const Messages = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  justify-content: flex-start;
  padding: 20px;
`
