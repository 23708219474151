import React, { memo, useContext } from 'react'

import { Messages, MessagesContainer } from './messages.styled'
import { Message } from './message'
import { ChatContext, IChatContext } from '../chat-context'
import { UserContext } from '../../../utils'

export const MessagesComponent = memo(() => {
  const messages = useContext(ChatContext)
  const { id } = useContext(UserContext)
  return (
    <MessagesContainer>
      <Messages>
        {id &&
          messages[id as keyof IChatContext].map(({ text, time, sender }) => (
            <Message key={text} text={text} left={sender} time={time} />
          ))}
      </Messages>
    </MessagesContainer>
  )
})

MessagesComponent.displayName = 'Messages'
