import styled, { css } from 'styled-components'
import { media } from '../../../../styles'

export const Wrapper = styled.span`
  width: 52%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px 20px 10px 5px;

  ${({ isLeft }: { isLeft: boolean }) =>
    isLeft
      ? css`
          align-self: flex-start;
          background-color: rgba(45, 170, 236, 0.55);
          color: ${({ theme }) => theme.palette.black};
        `
      : css`
          background-color: ${({ theme }) => theme.palette.white};
          color: ${({ theme }) => theme.palette.black};
          align-self: flex-end;
          border-radius: 20px 8px 5px 10px;
        `}

  & + & {
    margin-top: 15px;
  }

  ${media.lg`
      width: 55%;
  `}

  ${media.md`
      width: 60%;
  `}

  ${media.sm`
      width: 70%;
  `}
`

export const Text = styled.span``

export const Time = styled.span`
  font-family: 'Dosis', sans-serif;
  font-size: 14px;
  align-self: flex-end;
`
