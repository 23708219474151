import React, { memo } from 'react'
import { RouteComponentProps } from '@reach/router'

import { IQuestEdges } from '../../types'
import { ImageText, NoSelectImage, QuestCard, Wrapper } from './quests.styled'

interface IQuestsProps extends RouteComponentProps {
  quests: IQuestEdges[]
}

export const Quests = memo(({ quests }: IQuestsProps) => {
  return (
    <Wrapper>
      {quests.map(({ node: { name, image } }) => (
        <QuestCard key={name}>
          <NoSelectImage img={image.childImageSharp} />
          <ImageText>{name}</ImageText>
        </QuestCard>
      ))}
    </Wrapper>
  )
})

Quests.displayName = 'Quests'
