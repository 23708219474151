import React, { memo, PropsWithChildren } from 'react'
import { SendButton } from './send-button.styled'

interface ISendButtonProps {
  onClick: () => void
  disabled?: boolean
}

export const SendButtonComponents = memo(
  ({
    onClick,
    children,
    disabled = false,
    ...props
  }: PropsWithChildren<ISendButtonProps>) => {
    return (
      <SendButton {...props} disabled={disabled} onClick={onClick}>
        {children}
      </SendButton>
    )
  }
)

SendButtonComponents.displayName = 'SendButtonComponents'
