import React, { memo, PropsWithChildren } from 'react'

import { Button } from './button.styled'

interface IButtonProps {
  onClick: () => void
  outline?: boolean
}

export const ButtonComponent = memo(
  ({ onClick, children, outline }: PropsWithChildren<IButtonProps>) => {
    return (
      <Button onClick={onClick} outline={outline}>
        {children}
      </Button>
    )
  }
)

ButtonComponent.displayName = 'Button'
