import React, { PropsWithChildren } from 'react'
import { AiOutlinePhone } from 'react-icons/all'

import { StyledRouteLink, StyledSimpleLink } from '../../components'
import styled from 'styled-components'

interface ILinkProps {
  href: string
}

const PhoneLinkContent = styled.span`
  font-family: 'Dosis', sans-serif;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
`

export function LinkRenderer({
  children,
  href,
}: PropsWithChildren<ILinkProps>) {
  if (href.match(/^(https?:)?\/\//)) {
    return (
      <StyledSimpleLink href={href} target="_blank">
        {children}
      </StyledSimpleLink>
    )
  }

  if (href.match(/^(tel:)/)) {
    return (
      <StyledSimpleLink href={href}>
        <PhoneLinkContent>
          {children}
          <AiOutlinePhone size={14} />
        </PhoneLinkContent>
      </StyledSimpleLink>
    )
  }

  return <StyledRouteLink to={href}>{children}</StyledRouteLink>
}
