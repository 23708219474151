import React, { ChangeEvent, memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from '@reach/router'
import { AiOutlineEnter } from 'react-icons/all'

import { ChatContext } from './chat-context'
import {
  Button,
  ButtonText,
  InnerButton,
  InputRow,
  UserInput,
  Wrapper,
} from './chat-bot.styled'
import { Messages } from './messages'
import { chatBotData } from '../../data'

// eslint-disable-next-line no-unused-vars
export const ChatBot = memo((props: RouteComponentProps) => {
  const { t } = useTranslation()
  const [message, setMessage] = useState('')

  const handleInput = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target
    setMessage(value)
  }, [])

  return (
    <ChatContext.Provider value={chatBotData}>
      <Wrapper>
        <Messages />

        <InputRow>
          <UserInput
            value={message}
            onChange={handleInput}
            placeholder={t('chatBot.placeholder')}
          />
          <Button disabled={!message} onClick={() => {}}>
            <InnerButton>
              <ButtonText>{t('chatBot.button')}</ButtonText>
              <AiOutlineEnter />
            </InnerButton>
          </Button>
        </InputRow>
      </Wrapper>
    </ChatContext.Provider>
  )
})

ChatBot.displayName = 'ChatBot'
