import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { hover, media } from '../styles'

const Wrapper = styled.div`
  margin-left: 68px;
  ${media.xl`
      margin-left: 56px;
  `}
`

const Changer = styled.button`
  position: relative;
  background-color: rgba(0, 0, 0, 0.00005);
  padding: 0 5px;
  border: none;
  color: ${({ active }: { active: boolean }) =>
    active ? '#f2e90d' : '#fffffa'};
  font-size: 27px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &::after {
    position: absolute;
    bottom: 0;
    left: 5px;
    opacity: ${({ active }) => (active ? 1 : 0)};
    width: ${({ active }) => (active ? '40%' : 0)};
    height: 2px;
    content: '';
    background-color: #f2e90d;
    transition: opacity 0.2s ease-in-out, width 0.5s ease-in-out;
  }

  & + & {
    border-left: 2px solid #fffffa;
  }

  ${hover`
    color: #f2e90d;
    
    &::after {
      opacity: 1;
      width: 40%;
    }
  `}
`

export function LanguageChanger() {
  const { i18n } = useTranslation()

  const changeLanguage = (value: string) => () => i18n.changeLanguage(value)

  return (
    <Wrapper>
      <Changer active={i18n.language === 'ru'} onClick={changeLanguage('ru')}>
        Ру
      </Changer>
      <Changer active={i18n.language === 'en'} onClick={changeLanguage('en')}>
        Eng
      </Changer>
    </Wrapper>
  )
}
