import React, { useCallback } from 'react'
import { navigate } from 'gatsby'
import { BsGear, IoExitOutline, IoNotificationsOutline } from 'react-icons/all'

import { Icon } from '../header.styled'
import { removeId } from '../../../utils'

const tools = [
  {
    action: 'settings',
    icon: <Icon component={<BsGear />} />,
  },
  {
    action: 'notifications',
    icon: <Icon component={<IoNotificationsOutline />} />,
  },
  {
    action: 'exit',
    icon: <Icon component={<IoExitOutline />} />,
  },
]

export function useTools() {
  const handleExit = useCallback(() => {
    removeId()
    navigate('/unauthorised')
  }, [])

  const handlers: { [key: string]: () => void } = {
    settings: () => {},
    notifications: () => {},
    exit: handleExit,
  }

  return tools.map((tool) => ({
    ...tool,
    handler: handlers[tool.action],
  }))
}
