import styled from 'styled-components'

import { media, textInputStyles } from '../../styles'

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${media.sm`
    width: 100%;
    padding: 0 20px;
  `}
`

export const Input = styled.input.attrs({
  type: 'text',
})`
  display: block;
  width: 400px;
  ${textInputStyles};

  ${media.md`
    width: 350px;
    padding: 10px;
  `}

  ${media.sm`
    width: 100%;
  `}
`

export const Row = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${media.sm`
    width: 100%;
    flex-direction: column;
  `}
`
