import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import { defaultTransition, hover, media } from '../../../styles'

interface ILinkProps {
  disabled: boolean
  selected: boolean
}

export const NavigationItem = styled.li`
  width: 100%;
  height: 130px;
  background-color: ${({ selected }: { selected: boolean }) =>
    selected ? ({ theme }) => theme.palette.darkBlue : 'rgba(0, 0, 0, 0.0005)'};

  ${defaultTransition('background-color', 'height')}

  ${media.lg`
      height: 100px;
  `}

  ${media.sm`
      height: 50px;
  `}

  &:last-child {
    margin-bottom: 35px;

    ${media.md`
      margin-bottom: 25px;
    `}

    ${media.sm`
      margin-bottom: 10px;
    `}
  }
`

export const StyledLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  height: 100%;
  font-weight: 700;

  ${({ disabled }: ILinkProps) =>
    disabled &&
    css`
      pointer-events: none;
    `}

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
    opacity: 0;
    background-color: ${({ theme }: any) => theme.palette.blue};
    content: '';

    ${defaultTransition('opacity')}
  }

  ${({ selected }: ILinkProps) =>
    selected &&
    css`
      &::before {
        opacity: 1;
      }
    `}

  ${hover`
    &::before {
        opacity: 1;
    }
  `}
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  opacity: ${({ selected }: { selected: boolean }) => (selected ? '1' : '.6')};
  color: ${({ theme }) => theme.palette.white};

  ${defaultTransition('opacity')}

  ${media.lg`
     font-size: 15px;
  `}
`

export const Title = styled.span`
  margin-top: 12px;
  display: block;
  text-transform: capitalize;

  ${media.lg`
    margin-top: 8px;
  `}

  ${media.sm`
    display: none;
  `}
`
