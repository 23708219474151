import styled, { css } from 'styled-components'

export const NotFitElement = styled.div`
  position: relative;
  width: 100%;
  overflow-x: hidden;

  ${({ vertical }: { vertical: boolean }) =>
    vertical
      ? css`
          margin-top: -10px;
          margin-bottom: -10px;
        `
      : css`
          margin-left: -10px;
          margin-right: -10px;
        `}
`

export const Scroll = styled.div`
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  ${({ vertical }: { vertical: boolean }) =>
    vertical
      ? css`
          width: calc(100% + 20px);
          overflow-x: auto;
          overflow-y: scroll;
          padding-top: 10px;
          padding-bottom: 10px;
          background: linear-gradient(
              ${({ theme }) => theme.palette.region},
              ${({ theme }) => theme.palette.region}
            ),
            linear-gradient(
              ${({ theme }) => theme.palette.region},
              ${({ theme }) => theme.palette.region}
            ),
            linear-gradient(
              to top,
              rgba(0, 0, 0, 0.0005),
              ${({ theme }) => theme.palette.blue}
            ),
            linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0.0005),
              ${({ theme }) => theme.palette.blue}
            );
          background-position: 0 0, 0 100%, 0 0, 0 100%;
          background-repeat: no-repeat;
          background-color: rgba(0, 0, 0, 0.0005);
          background-size: 100% 80px, 100% 80px, 100% 40px, 100% 40px;
          background-attachment: local, local, scroll, scroll;
        `
      : css`
          overflow-x: auto;
          overflow-y: visible;
          margin-bottom: -20px;
          padding-bottom: 20px;
          padding-left: 10px;
          padding-right: 10px;
        `}
`

export const Content = styled.div`
  width: auto;
`
