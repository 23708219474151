import styled from 'styled-components'
import { defaultTransition, media } from '../../styles'

export const Wrapper = styled.main`
  width: 100%;
  height: auto;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 292px;
  background-color: ${({ theme }) => theme.palette.background};

  ${defaultTransition('padding-left')}

  ${media.xl`
    padding-left: 270px;
  `}
  
  ${media.lg`
    padding-left: 200px;
  `}

  ${media.md`
    padding-left: 150px;
  `}
  
  ${media.sm`
    padding-left: 40px;
  `}
`
