export interface ITheme {
  palette: {
    background: string
    header: string
    region: string
    regionHover: string
    blue: string
    darkBlue: string
    grey: string
    black: string
    green: string
    white: string
    body: string
  }
  shadow: string
  spacing: {
    unit: number
  }
}

const mainTheme = {
  palette: {
    background: '#eaeef1',
    header: '#fefefe',
    region: '#2859ad',
    blue: '#21aaec',
    darkBlue: '#1c4ba1',
    grey: '#afb6b9',
    black: '#26282b',
    green: '#68ba32',
    white: '#eae9ec',
    body: '#ffffff',
  },
  shadow: 'rgba(0, 0, 0, 0.16) 0 1px 4px',
  spacing: {
    unit: 8,
  },
}

export default mainTheme
