import React, { memo, useCallback, useContext, useRef, useState } from 'react'
import { IoMenuSharp } from 'react-icons/all'
import { useTranslation } from 'react-i18next'

import { Icon, NavigationMobileButton, Wrapper } from './header.styled'
import { Search } from './search'
import { navigation, Navigation } from './navigation'
import { NavigationItem } from './navigation-item'
import { ToolsItem } from './tools-item'
import { Tools, useTools } from './tools'
import { UserContext } from '../../utils'

interface IHeaderProps {
  pathname: string
}

export const Header = memo(({ pathname }: IHeaderProps) => {
  const { t } = useTranslation()
  const searchRef = useRef<HTMLInputElement>(null)
  const [isMobileNavigationShown, setMobileNavigationShown] = useState(false)
  const [isSearchFocused, setSearchFocused] = useState(false)
  const { user } = useContext(UserContext)
  const toolsHandlers = useTools()

  const handleMobileNavigation = useCallback(
    () => setMobileNavigationShown((value) => !value),
    []
  )

  const handleFocus = useCallback(() => setSearchFocused(true), [])
  const handleBlur = useCallback(() => setSearchFocused(false), [])

  const isDisabled = !user

  return (
    <Wrapper>
      <Search
        ref={searchRef}
        onFocus={handleFocus}
        onBlur={handleBlur}
        isSearchFocused={isSearchFocused}
        disabled={isDisabled}
      />

      <Navigation
        isHidden={isSearchFocused}
        mobileNavigation={isMobileNavigationShown}
      >
        {navigation.map(({ title, to }) => (
          <NavigationItem
            key={title}
            to={to}
            selected={to === pathname}
            disabled={isDisabled}
          >
            {t(title)}
          </NavigationItem>
        ))}
      </Navigation>

      <Tools>
        <NavigationMobileButton
          onClick={handleMobileNavigation}
          disabled={isDisabled}
        >
          <Icon component={<IoMenuSharp />} />
        </NavigationMobileButton>
        {toolsHandlers.map(({ icon, action, handler }) => (
          <ToolsItem key={action} onClick={handler} disabled={isDisabled}>
            {icon}
          </ToolsItem>
        ))}
      </Tools>
    </Wrapper>
  )
})

Header.displayName = 'Header'
