import React, { memo } from 'react'

import { Wrapper, Text, Time } from './message.styled'

interface IMessageProps {
  text: string
  time: string
  left?: boolean
}

export const Message = memo(({ text, time, left = false }: IMessageProps) => {
  return (
    <Wrapper isLeft={left}>
      <Text>{text}</Text>
      <Time>{time}</Time>
    </Wrapper>
  )
})

Message.displayName = 'Message'
