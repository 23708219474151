import React, { memo } from 'react'
import { RouteComponentProps } from '@reach/router'

import { Image } from '../common'
import { GatsbyImageFluidProps } from 'gatsby-image'
import { Wrapper } from './connections.styled'

interface IConnectionsProps extends RouteComponentProps {
  image: GatsbyImageFluidProps
}

export const Connections = memo(({ image }: IConnectionsProps) => (
  <Wrapper>{image && <Image img={image} />}</Wrapper>
))
