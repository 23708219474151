import React, { memo, PropsWithChildren } from 'react'

import { Content, NotFitElement, Scroll } from './scroll.styled'

interface IScrollProps {
  vertical?: boolean
}

export const ScrollComponent = memo(
  ({ children, vertical = false }: PropsWithChildren<IScrollProps>) => (
    <NotFitElement vertical={vertical}>
      <Scroll vertical={vertical}>
        <Content>{children}</Content>
      </Scroll>
    </NotFitElement>
  )
)

ScrollComponent.displayName = 'Scroll'
