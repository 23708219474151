import styled, { css } from 'styled-components'

import { defaultTransition, hover, media } from '../../../styles'

export const Button = styled.button`
  font-size: 15px;
  color: ${({ theme }) => theme.palette.white};
  border-radius: 20px;
  padding: 10px 16px;
  cursor: pointer;
  border: none;

  ${defaultTransition('background-color', 'padding', 'font-size')}

  ${({ outline }: { outline?: boolean }) =>
    outline
      ? css`
          border: solid 1px ${({ theme }) => theme.palette.blue};
          background-color: rgba(0, 0, 0, 0.005);
          ${hover`
            background-color: ${({ theme }: any) => theme.palette.blue}
          `}
        `
      : css`
          background-color: ${({ theme }) => theme.palette.blue};
        `}
  
  ${media.lg`
      font-size: 13px;
      padding: 5px 8px;
  `}

  ${media.md`
      padding: 3px 5px;
  `}
`
