export const messages = [
  {
    text: 'Здравствуйте, Витовт! Готовы ли Вы начать Вводный курс?',
    time: '13:00',
    sender: true,
  },
  {
    text: 'Да, я готов.',
    time: '13:01',
    sender: false,
  },
  {
    text: 'Поздравляем Вас с началом Вводного курса!',
    time: '13:01',
    sender: true,
  },
  {
    text: 'Поздравляем, вы успешно прошли квест История компании!',
    time: '14:54',
    sender: true,
  },
  {
    text: 'Поздравляем, вы успешно прошли квест «Росатом» сегодня!',
    time: '16:34',
    sender: true,
  },
  {
    text: 'На чем я остановился?',
    time: '17:32',
    sender: false,
  },
  {
    text: 'Вы остановились на странице Структура компании',
    time: '17:32',
    sender: true,
  },
  {
    text: 'Каков мой прогресс?',
    time: '17:35',
    sender: false,
  },
  {
    text: 'Вы прошли Вводный курс на 50 %.',
    time: '17:35',
    sender: true,
  },
  {
    text:
      'Поздравляем, вы успешно прошли квест Структура и деятельность компании!',
    time: '18:27',
    sender: true,
  },
  {
    text: 'Поздравляем, вы успешно прошли квест Миссия и ценности компании!',
    time: '19:50',
    sender: true,
  },
  {
    text:
      'Поздравляем, вы успешно прошли квест Единая цифровая стратегия ГК «РосАтом»!',
    time: '20:50',
    sender: true,
  },
  {
    text: 'Поздравляем, вы успешно прошли Вводный курс!',
    time: '20:50',
    sender: true,
  },
  {
    text: 'Понравился ли Вам курс?',
    time: '20:51',
    sender: true,
  },
  {
    text: 'Да, было очень интересно!',
    time: '20:51',
    sender: false,
  },
]
