import styled from 'styled-components'

import { defaultTransition, hover, media } from '../../styles'
import { Image } from '../common'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

export const ImageText = styled.span`
  position: relative;
  font-family: inherit;
  text-align: center;
  font-size: 16px;

  &::after {
    position: absolute;
    bottom: -3px;
    left: 50%;
    height: 3px;
    width: 0;
    opacity: 0;
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.palette.blue};
    content: '';

    ${defaultTransition('width', 'opacity')};
  }

  ${media.sm`
      font-size: 12px;
  `}
`

export const QuestCard = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  height: 300px;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 0 0 3px ${({ theme }) => theme.palette.grey};
  padding: 10px;
  margin: 10px;
  background-color: ${({ theme }) => theme.palette.body};
  ${defaultTransition('box-shadow', 'width', 'height')};

  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    transform: translate(-50%);
    background-color: rgba(0, 0, 0, 0.12);
    content: '';
    ${defaultTransition('width', 'height')}
  }

  ${hover`
    box-shadow: 0 0 0 3px ${({ theme }: any) => theme.palette.blue};
    
    &::after {
      width: 100%;
      height: 100%;
    }
    
    ${ImageText} {
      &::after {
        width: 100%;
        opacity: 1;
      }
    }
  `}

  ${media.md`
    width: 200px;
    height: 250px;
  `}

  ${media.sm`
    width: 120px;
    height: 170px;
  `}
`

export const NoSelectImage = styled(Image)`
  user-select: none;
`
