import React, { memo } from 'react'
import { Router } from '@reach/router'

import { Layout } from './content.styled'
import { ChatBot } from '../chat-bot'
import { Connections } from '../connections'
import { IChildImageSharp, IQuestEdges } from '../../types'
import { Quests } from '../quests'
import { TextPage } from '../text-page'

import job from '../../data/markdown-pages/job'
import questions from '../../data/markdown-pages/questions'
import company from '../../data/markdown-pages/company'
import contacts from '../../data/markdown-pages/contacts'
import documents from '../../data/markdown-pages/documents'
import info from '../../data/markdown-pages/info'

interface IContentProps {
  connectionsImage: IChildImageSharp
  quests: IQuestEdges[]
}

export const Content = memo(({ connectionsImage, quests }: IContentProps) => {
  return (
    <Layout>
      <Router basepath="/app">
        <ChatBot path="/chat-bot" />
        <Connections
          image={connectionsImage.childImageSharp}
          path="/connections"
        />
        <Quests path="/dashboard" quests={quests} />
        <TextPage path="/info" content={info} />
        <TextPage path="/job" content={job} />
        <TextPage path="/questions" content={questions} />
        <TextPage path="/company" content={company} />
        <TextPage path="/contacts" content={contacts} />
        <TextPage path="/docs" content={documents} />
      </Router>
    </Layout>
  )
})

Content.displayName = 'Content'
