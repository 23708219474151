import React, { memo } from 'react'
import ReactMarkdown from 'react-markdown'
import { RouteComponentProps } from '@reach/router'

import { ContentLayout } from '../content/content.styled'
import { HeaderRenderer } from '../../utils'
import { LinkRenderer } from '../../utils/renderers/link'

interface ITextPageProps extends RouteComponentProps {
  content: string
}

// eslint-disable-next-line no-unused-vars
export const TextPage = memo(({ content }: ITextPageProps) => (
  <ContentLayout>
    <ReactMarkdown
      source={content}
      renderers={{ heading: HeaderRenderer, link: LinkRenderer }}
    />
  </ContentLayout>
))

TextPage.displayName = 'Job'
