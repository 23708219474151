import React from 'react'
import i18n from './config'
import { I18nextProvider, withTranslation } from 'react-i18next'

export function withTranslationHOC<T extends object>(
  Component: React.ComponentType<T>
) {
  const WrappedComponent = withTranslation()(Component)

  return function (props: T) {
    return (
      <I18nextProvider i18n={i18n}>
        <WrappedComponent {...props} i18n={i18n} />
      </I18nextProvider>
    )
  }
}
