import styled, { StyledComponent } from 'styled-components'
import Img, { GatsbyImageFluidProps } from 'gatsby-image'

export const Image: StyledComponent<
  typeof Img,
  GatsbyImageFluidProps,
  GatsbyImageFluidProps
> = styled(Img)`
  width: 100%;
`
