export default `# Документы

[Приложение № 1 ТРУДОВОЙ ДОГОВОР с научным работником- исполнителем государственной работы «Проведение научно-исследовательских работ»](https://etu.ru/assets/files/sotrudniku/Prikazi/Prikaz1477-pril1.doc)

[Таблицы окладов и порядок определения фонда оплаты](https://etu.ru/assets/files/sotrudniku/upbuifk/pril1_2_2206.xlsx)

[Штатное расписание ППС](https://etu.ru/assets/files/sotrudniku/Prikazi/pril3_2206.xlsx)

[Реализации мер по снижению рисков распространения новой коронавирусной инфекции](https://etu.ru/assets/files/soc-vosp/obsh/prikaz-ol_0476-ot-12-11-2020-o-merah-po-snizh-riskov-v-obshhezhitiyah.pdf)

[Стандарт безопасности](https://etu.ru/assets/files/ru/coronavirus/rasporyaghenie_2020.04.10_0047.pdf)

[Руководство системного программиста](https://stilsoft.ru/file/rukovodstvo-sistemnogo-programmista-polosa.pdf-ae532d98a29997e8d9392aaab022c866)

[Подготовка документации на программные средства](http://www.interface.ru/home.asp?artId=3293)

[Виды программных средств](http://starik2222.narod.ru/trpp/lec2/28.htm)

[Договор на разработка](https://www.documentoved.ru/documents/special-documents/dogovor-na-razrabotku)

[Аккредитация компании](https://www.documentoved.ru/documents/special-documents/gosudarstvennaya-akkreditaciya)

[Регистрация программы ЭВМ](https://www.documentoved.ru/documents/special-documents/registraciya-programmi-EVM-ili-bazi-dannih)

[Договор об отчуждении исключительных прав](https://www.documentoved.ru/documents/special-documents/otchujdenie-isklyuchitelnih-prav)
`
