import React, { memo, PropsWithChildren } from 'react'

import { StyledLink, Wrapper } from './navigation-item.styled'

interface INavigationItemProps {
  to: string
  selected: boolean
  onClick?: () => void
  disabled?: boolean
}

export const NavigationItem = memo(
  ({
    children,
    to,
    onClick,
    selected,
    disabled = false,
  }: PropsWithChildren<INavigationItemProps>) => (
    <Wrapper onClick={onClick}>
      <StyledLink to={to} selected={selected} disabled={disabled}>
        {children}
      </StyledLink>
    </Wrapper>
  )
)

NavigationItem.displayName = 'NavigationItem'
