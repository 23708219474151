import React, { memo, PropsWithChildren } from 'react'

import { Wrapper } from './tools.styled'

interface IToolsProps {}

export const Tools = memo(({ children }: PropsWithChildren<IToolsProps>) => {
  return <Wrapper>{children}</Wrapper>
})

Tools.displayName = 'Tools'
