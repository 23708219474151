import React, { PropsWithChildren } from 'react'

import { H1, H2, H3, Paragraph } from '../../components'

interface IHeaderRenderProps {
  level: number
}

export function HeaderRenderer({
  children,
  level,
}: PropsWithChildren<IHeaderRenderProps>) {
  switch (level) {
    case 1:
      return <H1>{children}</H1>
    case 2:
      return <H2>{children}</H2>
    case 3:
      return <H3>{children}</H3>
    case 4:
    case 5:
    case 6:
    default:
      return <Paragraph>{children}</Paragraph>
  }
}
