import React, { memo, PropsWithChildren } from 'react'

import { Wrapper } from './main.styled'

interface IMainProps {}

export const Main = memo(({ children }: PropsWithChildren<IMainProps>) => {
  return <Wrapper>{children}</Wrapper>
})

Main.displayName = 'Main'
