import React, { memo, PropsWithChildren } from 'react'

import { Navigation, NavigationList } from './navigation.styled'

interface INavigationProps {}

export const NavigationComponent = memo(
  ({ children }: PropsWithChildren<INavigationProps>) => (
    <Navigation>
      <NavigationList>{children}</NavigationList>
    </Navigation>
  )
)

NavigationComponent.displayName = 'RegionNavigation'
