import { css } from 'styled-components'
import { defaultTransition, hover, media } from './style-utils'

export const textInputStyles = css`
  padding: 15px;
  font-family: 'Dosis', sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: ${({ theme }: any) => theme.palette.black};
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 4px;
  border: none;
  box-shadow: 0 0 0 3px ${({ theme }) => theme.palette.grey};

  ${defaultTransition('box-shadow', 'background-color', 'width')};

  &::placeholder {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.grey};
    ${defaultTransition('color')};
  }

  ${hover`
    box-shadow: 0 0 0 3px ${({ theme }: any) => theme.palette.blue};
  `}

  &:focus {
    background-color: ${({ theme }) => theme.palette.body};

    &::placeholder {
      color: ${({ theme }: any) => theme.palette.black};
    }
  }

  ${media.md`
    padding: 10px;
  `}
`
