export default `# Информация
  
  ## Регион
  
  Приложение состоит из боковой панели слева (так называемый регион), включающую в себя следующие элементы навигации:
  1. [Панель](/app/dashboard) управления курсами (обучающими квестами)
  2. Информация об [обязанностях](/app/job) (список для сотрудника)
  3. Актуальные нормативные [документы](/app/docs) компании
  4. Информация о [связях и взаимоотношениях](/app/connections) в компании (Иерархия компании с контактными данными)
  
  ## Основная навигационная панель
  
  Также в приложении присутствует основная навигационная панель сверху над каждой страницей. С ее помощью доступны следующие функции:
  1. Доступ к этой пояснительной [информации](/app/info) о приложении
  2. Доступ к [вопросам](/app/questions), на которые необходимо ответить на текущий момент
  3. Новости [компании](/app/company)
  4. Записная книжка с [контактами](/app/contacts)
  5. Автоматический [чат-бот](/app/chat-bot), готовый ответить на вопросы сотрудника
  6. Панель инструментов: настройки, оповещения и кнопка выхода.
`
