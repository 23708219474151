import React, { memo, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { ImageComponent } from '../common/image/image'
import {
  Avatar,
  Container,
  Name,
  Role,
  UpdateProfileButton,
  Wrapper,
} from './region.styled'
import { Button, Scroll } from '../common'
import { Navigation, navigationItems } from './navigation'
import { NavigationItem } from './navigation-item'
import { UserContext } from '../../utils'
import { IUser } from '../../types'

interface IRegionProps {
  pathname: string
  defaultUser?: IUser | undefined
}

export const Region = memo(({ defaultUser, pathname }: IRegionProps) => {
  const { t } = useTranslation()
  const { id, user: contextUser } = useContext(UserContext)

  const user = contextUser || defaultUser

  const avatar = user?.image?.childImageSharp
  const name = user?.name
  const role = user?.role

  const isLoading = (!id && pathname !== '/unauthorised') || !avatar || !name

  return (
    <Wrapper>
      <Scroll vertical>
        <Container>
          <Avatar isLoading={isLoading}>
            {avatar && <ImageComponent img={avatar} />}
          </Avatar>
          <Name isLoading={isLoading}>{name}</Name>
          {pathname !== '/unauthorised' && (
            <>
              <Role isLoading={isLoading}>{role}</Role>

              <UpdateProfileButton isLoading={isLoading}>
                <Button onClick={() => {}} outline>
                  {t('region.updateProfileButton')}
                </Button>
              </UpdateProfileButton>

              <Navigation>
                {navigationItems.map(({ to, name, icon }) => (
                  <NavigationItem
                    to={to}
                    icon={icon}
                    key={name}
                    selected={to === pathname}
                    disabled={isLoading}
                  >
                    {t(name)}
                  </NavigationItem>
                ))}
              </Navigation>
            </>
          )}
        </Container>
      </Scroll>
    </Wrapper>
  )
})

Region.displayName = 'Region'
