export default `# Доступные вопросы

Привет! Здесь ты можешь отслеживать доступные тестовые задачи, которые тебе необходимо выполнить, чтобы максимально быстро влиться в нашу **крутую компанию**. 


## Личностные качества 

1. [Скрытые таланты](https://onlinetestpad.com/ru/testview/51207-vashi-skrytye-talantytest).
2. [Исследование тревожности](https://onlinetestpad.com/ru/testview/714-test-issledovanie-trevozhnosti-oprosnik-spilbergera).
3. [Тест DISC](https://onlinetestpad.com/ru/testview/635909-test-disc-opredelite-vash-povedencheskij-tip).
4. [Определение профессиональной направленности личности ](https://onlinetestpad.com/ru/testview/867113-test-opredeleniya-professionalnoj-napravlennosti-lichnosti-dzh-khollanda).
5. [Проверка когнитивных способностей](https://onlinetestpad.com/ru/testview/140071-kot-kratkij-otborochnyj-test).

## Жизнь компании

1. [История компании](https://onlinetestpad.com/ru/testview/868009-obrazovanie-i-razvitie-drevnerusskogo-gosudarstva-obrazovatelnyj-test-dlya).
2. [Культура компании](https://onlinetestpad.com/ru/testview/868054-izobrazitelnoe-iskusstvo).
3. [Миссия и цели](https://onlinetestpad.com/ru/testview/868160-istoriya-rossii-8-klass-socialnaya-struktura-rossijskogo-obshhestva-vtoroj).
4. [Корпоративная жизнь](https://onlinetestpad.com/ru/testview/868662-khangyl-v-teste-proveryaetsya-znanie-bukv-korejskogo-alfavita-pravil-sostav).


## Инфраструктура
1. [Инфраструктура компании](https://onlinetestpad.com/ru/testview/868578-ekzamenacionnye-voprosy-po-attestacii-po-bdd-2021-3-chast).
2. [Структура персонала](https://onlinetestpad.com/ru/testview/80771-tip-myshleniya).
3. [Команда](https://onlinetestpad.com/ru/testview/868160-istoriya-rossii-8-klass-socialnaya-struktura-rossijskogo-obshhestva-vtoroj).
4. [Проекты](https://onlinetestpad.com/ru/testview/868662-khangyl-v-teste-proveryaetsya-znanie-bukv-korejskogo-alfavita-pravil-sostav).


## Профессия

1. [Стек технологий](https://onlinetestpad.com/ru/testview/868578-ekzamenacionnye-voprosy-po-attestacii-po-bdd-2021-3-chast).
2. [Репозиторий](https://onlinetestpad.com/ru/testview/80771-tip-myshleniya).
3. [База данных](https://onlinetestpad.com/ru/testview/868160-istoriya-rossii-8-klass-socialnaya-struktura-rossijskogo-obshhestva-vtoroj).
4. [Команда](https://onlinetestpad.com/ru/testview/868662-khangyl-v-teste-proveryaetsya-znanie-bukv-korejskogo-alfavita-pravil-sostav).
`
