import { messages as vitaly } from './vitaly'
import { messages as artem } from './artem'
import { messages as nastya } from './nastya'
import { messages as vitovt } from './vitovt'

export const chatBotData = {
  '00001': vitaly,
  '00002': artem,
  '00003': vitovt,
  '00004': nastya,
}
