import styled, { css } from 'styled-components'

import { media } from '../../../styles'

interface WrapperProps {
  mobileNavigation: boolean
  isHidden: boolean
}

export const Wrapper = styled.nav`
  z-index: 2;
  width: auto;
  height: 100%;

  ${media.sm`
     display: none;
     
     ${({ mobileNavigation }: WrapperProps) =>
       mobileNavigation &&
       css`
         position: absolute;
         top: 40px;
         right: 0;
         display: block !important;
         height: 300px;
         padding: 20px 0;
         background-color: ${({ theme }) => theme.palette.header};
         box-shadow: ${({ theme }) => theme.shadow};
       `}
  `}

  ${({ isHidden }: WrapperProps) =>
    isHidden &&
    css`
      display: none;
    `}
`

export const NavigationList = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  width: auto;

  ${media.sm`
     flex-direction: column;
  `}
`
