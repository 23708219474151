import { css, ThemedCssFunction } from 'styled-components'
import { ITheme } from './theme'

const sizes = {
  xxl: 2560,
  xl: 1440,
  lg: 1366,
  md: 1024,
  sm: 768,
}

export const gradientAnimation = css`
  background: linear-gradient(
    -90deg,
    ${({ theme }) => theme.palette.blue},
    ${({ theme }) => theme.palette.darkBlue},
    ${({ theme }) => theme.palette.darkBlue},
    ${({ theme }) => theme.palette.blue}
  );
  background-size: 400% 400%;
  animation: gradient 5s ease-in-out infinite;

  @keyframes gradient {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`

export const defaultTransition = (...props: string[]) => css`
  transition: ${props.reduce((res, prop, idx, arr) => {
    res += `${prop} .2s ease-in-out${
      arr.length > 1 && arr.length - 1 !== idx ? ',' : ''
    }`

    return res
  }, '')};
`

export const media = (Object.keys(sizes) as Array<keyof typeof sizes>).reduce(
  (accumulator, label) => {
    const emSize = sizes[label] / 16
    accumulator[label] = (first: any, ...interpolations: any[]) => css`
      @media (max-width: ${emSize}em) {
        ${css(first, ...interpolations)}
      }
    `
    return accumulator
  },
  // eslint-disable-next-line no-unused-vars
  {} as { [key in keyof typeof sizes]: ThemedCssFunction<ITheme> }
)

export const px2rem = (px: number) => `${px / 16}rem`

export const hover = (first: any, ...interpolations: any[]) => css`
  &:focus,
  &:active {
    outline: none;
  }

  &:hover,
  &:focus,
  &:active {
    ${css(first, ...interpolations)}
  }
`
