import styled from 'styled-components'

import { defaultTransition, hover, media } from '../../../styles'

export const Wrapper = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: auto;

  & + & {
    margin-left: 25px;

    ${media.md`
      margin-left: 20px;
    `}

    ${media.sm`
      margin-left: 10px;
    `}
  }
`

export const IconButton = styled.button`
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: rgba(0, 0, 0, 0.0005);
  color: ${({ theme }) => theme.palette.grey};
  ${defaultTransition('color')}

  &:disabled {
    ${hover`
      cursor: not-allowed;
      color: ${({ theme }: any) => theme.palette.grey};
  `}
  }

  ${hover`
    color: ${({ theme }: any) => theme.palette.blue}
  `}
`
