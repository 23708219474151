import styled, { css } from 'styled-components'

import { defaultTransition, gradientAnimation, media } from '../../styles'

const skeleton = css`
  position: relative;
  min-width: 60%;
  min-height: 20px;
  text-align: center;

  &::after {
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border-radius: 4px;
    opacity: 0;
    content: '';
    ${defaultTransition('opacity')}
  }

  ${({ isLoading }: { isLoading: boolean }) =>
    isLoading &&
    css`
      user-select: none;

      &::after {
        z-index: 1;
        opacity: 1;
        ${gradientAnimation};
      }
    `}
`

export const Wrapper = styled.div`
  position: fixed;
  width: 292px;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.region};

  ${defaultTransition('width')}

  ${media.xl`
    width: 270px;
  `}

  ${media.lg`
    width: 200px;
  `}

  ${media.md`
    width: 150px;
  `}

  ${media.sm`
    width: 40px;
  `}
`

export const Container = styled.aside`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 35px;
  width: 292px;
  height: 100vh;

  ${defaultTransition('padding-top', 'height', 'width')}

  ${media.xl`
    width: 270px;
  `}

  ${media.lg`
    width: 200px;
  `}
  
  ${media.md`
     width: 150px;
     padding-top: 25px;
  `}

  ${media.sm`
    width: 40px;
    padding-top: 10px;
  `}
`

export const Avatar = styled.div`
  display: flex;
  border-radius: 50%;
  overflow: hidden;
  min-width: 130px;
  max-width: 130px;
  width: 130px;
  min-height: 130px;
  max-height: 130px;
  height: 130px;
  ${defaultTransition('width', 'height')}

  ${({ isLoading }: { isLoading: boolean }) => isLoading && gradientAnimation}

  ${media.sm`
    min-width: 36px;
    max-width: 36px;
    width: 36px;
    min-height: 36px;
    max-height: 36px;
    height: 36px;
  `}
`

export const Name = styled.span`
  margin-top: 25px;
  color: ${({ theme }) => theme.palette.white};
  font-size: 18px;
  font-weight: 700;
  ${defaultTransition('font-size')};
  ${skeleton};

  ${media.md`
    font-size: 14px;
  `}

  ${media.sm`
    display: none;
  `}
`

export const Role = styled.span`
  margin-top: 10px;
  color: ${({ theme }) => theme.palette.blue};
  font-size: 17px;
  ${defaultTransition('font-size')};
  ${skeleton};

  ${media.md`
    font-size: 13px;
  `}

  ${media.sm`
    display: none;
  `}
`

export const UpdateProfileButton = styled.div`
  margin-top: 20px;
  ${skeleton};

  ${media.sm`
      display: none;
  `}
`
