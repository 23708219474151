import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { defaultTransition, hover } from '../../styles'

export const defaultTypography = css`
  font-family: inherit;
  font-weight: 700;
  font-size: 16px;
`

export const H1 = styled.h1`
  ${defaultTypography};
  font-size: 18px;
`

export const H2 = styled.h2`
  ${defaultTypography};
`

export const H3 = styled.h3`
  ${defaultTypography};
  font-size: 15px;
`

export const Paragraph = styled.p`
  ${defaultTypography};
  font-size: 14px;
  font-weight: 400;
`

export const Strong = styled.strong`
  ${defaultTypography};
  font-size: 14px;
`

const linkStyles = css`
  position: relative;
  cursor: pointer;
  font-size: inherit;
  text-decoration: none;
  font-weight: 700;
  line-height: 2;
  color: ${({ theme }) => theme.palette.blue};

  ${defaultTransition('color')};

  &::after {
    position: absolute;
    bottom: -2px;
    left: 50%;
    height: 2px;
    width: 0;
    opacity: 0;
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.palette.blue};
    content: '';

    ${defaultTransition('width', 'opacity')};
  }

  ${hover`
      color: ${({ theme }: any) => theme.palette.region};

      &::after {
        width: 100%;
        opacity: 1;
      }
  `}
`

export const StyledSimpleLink = styled.a`
  ${linkStyles}
`

export const StyledRouteLink = styled(Link)`
  ${linkStyles}
`
