import styled from 'styled-components'

export const Navigation = styled.nav`
  width: 100%;
`

export const NavigationList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  list-style: none;
  padding-left: 0;
`
