export const messages = [
  {
    text: 'Здравствуйте, Виталий! Готовы ли Вы начать Вводный курс?',
    time: '10:00',
    sender: true,
  },
  {
    text: 'Да, я готов.',
    time: '10:01',
    sender: false,
  },
  {
    text: 'Поздравляем Вас с началом Вводного курса!',
    time: '10:02',
    sender: true,
  },
  {
    text: 'Поздравляем, вы успешно прошли квест История компании!',
    time: '12:45',
    sender: true,
  },
  {
    text: 'На чем я остановился?',
    time: '13:32',
    sender: false,
  },
  {
    text: 'Вы остановились на странице Направления деятельности Госкорпорации',
    time: '13:32',
    sender: true,
  },
  {
    text: 'Поздравляем, вы успешно прошли квест «Росатом» сегодня!',
    time: '14:34',
    sender: true,
  },
  {
    text:
      'Поздравляем, вы успешно прошли квест Структура и деятельность компании!',
    time: '15:45',
    sender: true,
  },
  {
    text: 'Поздравляем, вы успешно прошли квест Миссия и ценности компании!',
    time: '17:50',
    sender: true,
  },
  {
    text: 'Каков мой прогресс?',
    time: '17:53',
    sender: false,
  },
  {
    text: 'Вы прошли Вводный курс на 83 %.',
    time: '17:53',
    sender: true,
  },
  {
    text:
      'Поздравляем, вы успешно прошли квест Единая цифровая стратегия ГК «РосАтом»!',
    time: '18:43',
    sender: true,
  },
  {
    text: 'Поздравляем, вы успешно прошли Вводный курс!',
    time: '18:44',
    sender: true,
  },
  {
    text: 'Понравился ли Вам курс?',
    time: '18:45',
    sender: true,
  },
  {
    text: 'Да, было очень интересно!',
    time: '18:45',
    sender: false,
  },
  {
    text: 'Сколько времени я потратил на прохождение курса?',
    time: '18:45',
    sender: false,
  },
  {
    text: 'На прохождение курса вы потратили 8 ч. 30 мин.',
    time: '18:45',
    sender: true,
  },
]
