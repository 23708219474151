import React, { memo } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImageFluidProps } from 'gatsby-image'

import { Image } from './image.styled'

interface IImageProps {
  img: GatsbyImageFluidProps
}

export const ImageComponent = memo(({ img }: IImageProps) => (
  <Image fluid={img.fluid} />
))

export const data = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

ImageComponent.displayName = 'Image'
