import styled from 'styled-components'

import { defaultTransition, hover, media } from '../../../styles'

export const SendButton = styled.button`
  cursor: pointer;
  margin-left: 25px;
  padding: 15px;
  display: block;
  font-family: inherit;
  font-size: 20px;
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.palette.blue};
  border-radius: 4px;
  border: none;
  box-shadow: 0 0 0 3px ${({ theme }) => theme.palette.blue};

  ${defaultTransition(
    'background-color',
    'color',
    'margin-left',
    'width',
    'box-shadow'
  )};

  &:not(:disabled) {
    ${hover`
    color: ${({ theme }: any) => theme.palette.blue};
    background-color: ${({ theme }: any) => theme.palette.body};
  `}
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }: any) => theme.palette.white};
    background-color: ${({ theme }: any) => theme.palette.grey};
    box-shadow: 0 0 0 3px ${({ theme }) => theme.palette.grey};
  }

  ${media.md`
    margin-left: 15px;
    padding: 10px;
  `}

  ${media.sm`
    margin-left: 0;
    margin-top: 20px;
    width: 40%;
  `}
`
