export const navigation = [
  {
    title: 'header.navigation.info',
    to: '/app/info',
  },
  {
    title: 'header.navigation.questions',
    to: '/app/questions',
  },
  {
    title: 'header.navigation.company',
    to: '/app/company',
  },
  {
    title: 'header.navigation.contacts',
    to: '/app/contacts',
  },
  {
    title: 'header.navigation.chatBot',
    to: '/app/chat-bot',
  },
]
