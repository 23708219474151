import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import { defaultTransition, hover, media } from '../../../styles'

interface ILinkProps {
  disabled: boolean
  selected: boolean
}

export const Wrapper = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  ${defaultTransition('margin-left')}

  & + & {
    margin-left: 30px;

    ${media.md`
      margin-left: 20px;
  `}

    ${media.sm`
      margin-top: 10px;
      margin-left: 0;
    `}
  }
`

export const StyledLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  height: 100%;
  font-family: inherit;
  white-space: nowrap;
  font-size: 18px;
  color: ${({ selected }: ILinkProps) =>
    selected
      ? ({ theme }) => theme.palette.blue
      : ({ theme }) => theme.palette.black};

  ${({ disabled }: ILinkProps) =>
    disabled &&
    css`
      pointer-events: none;
    `}

  ${defaultTransition('color', 'font-size')};

  ${media.lg`
    font-size: 16px;
  `}

  ${media.md`
    font-size: 14px;
  `}

  ${media.sm`
    width: 150px;
  `}

  &::before {
    position: absolute;
    left: 50%;
    bottom: 0;
    height: 3px;
    width: calc(100% + 10px);
    transform: translateX(-50%);
    opacity: 0;
    background-color: ${({ theme }: any) => theme.palette.blue};
    content: '';

    ${defaultTransition('opacity')}

    ${media.sm`
      top: 0; 
      left: 0;
      width: 3px;
      height: 100%;
      transform: none;
    `}
  }

  ${({ selected }: { selected: boolean }) =>
    selected &&
    css`
      &::before {
        opacity: 1;
      }
    `}

  ${hover`
    &::before {
        opacity: 1;
    }
  `}
`
