import React, { forwardRef, useCallback } from 'react'

import { Input, SearchIcon, Wrapper } from './search.styled'

interface ISearchProps {
  onFocus: () => void
  onBlur: () => void
  isSearchFocused: boolean
  disabled?: boolean
}

export const Search = forwardRef<HTMLInputElement, ISearchProps>(
  (
    { onFocus, onBlur, isSearchFocused, disabled = false }: ISearchProps,
    ref: any
  ) => {
    const handleWrapperClick = useCallback(() => {
      if (ref.current) {
        ref.current.focus()
      }
    }, [])

    return (
      <Wrapper
        onClick={handleWrapperClick}
        onFocus={onFocus}
        onBlur={onBlur}
        isSearchFocused={isSearchFocused}
      >
        <SearchIcon />
        <Input ref={ref} disabled={disabled} />
      </Wrapper>
    )
  }
)

Search.displayName = 'Search'
