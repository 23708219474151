import React, { memo, PropsWithChildren } from 'react'
import { ThemeProvider } from 'styled-components'

import theme from '../../styles/theme'
import GlobalStyles from '../../styles/global-styles'
import { Meta } from '../index'
import { Wrapper } from './layout.styled'

interface ILayoutProps {
  location: {
    pathname: string
  }
}

export const Layout = memo(
  ({ children, location }: PropsWithChildren<ILayoutProps>) => (
    <Wrapper>
      <GlobalStyles />
      <Meta pathname={location.pathname} />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </Wrapper>
  )
)

Layout.displayName = 'Layout'
