import React, { memo, PropsWithChildren } from 'react'

import { NavigationList, Wrapper } from './navigation.styled'

interface INavigationProps {
  isHidden: boolean
  mobileNavigation: boolean
}

export const Navigation = memo(
  ({
    isHidden,
    mobileNavigation,
    children,
  }: PropsWithChildren<INavigationProps>) => (
    <Wrapper mobileNavigation={mobileNavigation} isHidden={isHidden}>
      <NavigationList>{children}</NavigationList>
    </Wrapper>
  )
)

Navigation.displayName = 'HeaderNavigation'
