export const messages = [
  {
    text: 'Здравствуйте, Артем! Готовы ли Вы начать Вводный курс?',
    time: '11:00',
    sender: true,
  },
  {
    text: 'Да, я готов.',
    time: '11:01',
    sender: false,
  },
  {
    text: 'Поздравляем Вас с началом Вводного курса!',
    time: '11:02',
    sender: true,
  },
  {
    text: 'Поздравляем, вы успешно прошли квест История компании!',
    time: '12:45',
    sender: true,
  },
  {
    text: 'На чем я остановился?',
    time: '14:32',
    sender: false,
  },
  {
    text: 'Вы остановились на странице Направления деятельности Госкорпорации',
    time: '15:32',
    sender: true,
  },
  {
    text: 'Каков мой прогресс?',
    time: '15:33',
    sender: false,
  },
  {
    text: 'Вы прошли Вводный курс на 16 %.',
    time: '15:33',
    sender: true,
  },
  {
    text: 'Поздравляем, вы успешно прошли квест «Росатом» сегодня!',
    time: '15:34',
    sender: true,
  },
  {
    text:
      'Поздравляем, вы успешно прошли квест Структура и деятельность компании!',
    time: '16:45',
    sender: true,
  },
  {
    text: 'Поздравляем, вы успешно прошли квест Миссия и ценности компании!',
    time: '17:50',
    sender: true,
  },
  {
    text:
      'Поздравляем, вы успешно прошли квест Единая цифровая стратегия ГК «РосАтом»!',
    time: '19:43',
    sender: true,
  },
  {
    text: 'Поздравляем, вы успешно прошли Вводный курс!',
    time: '19:44',
    sender: true,
  },
  {
    text: 'Понравился ли Вам курс?',
    time: '19:45',
    sender: true,
  },
  {
    text: 'Да, но мне хотелось бы больше узнать о компании.',
    time: '19:46',
    sender: false,
  },
  {
    text: 'Благодарим Вас, мы учтем Ваши пожелания в будущем!',
    time: '19:46',
    sender: true,
  },
  {
    text: 'Сколько времени я потратил на прохождение курса?',
    time: '19:47',
    sender: false,
  },
  {
    text: 'На прохождение курса вы потратили 9 ч. 23 мин.',
    time: '19:47',
    sender: true,
  },
]
