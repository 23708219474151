export default `# Обязанности

1. Общие положения
1. Программист относится к категории специалистов.

2. Программист I категории: высшее профессиональное (техническое или инженерно-экономическое) образование и стаж работы в должности программиста II категории не менее 3 лет.

(-программист II категории: высшее профессиональное (техническое или инженерно-экономическое) образование и стаж работы в должности программиста III категории или других инженерно-технических должностях, замещаемых специалистами с высшим профессиональным образованием, не менее 3 лет.

-программист III категории: высшее профессиональное (техническое или инженерно-экономическое) образование и опыт работы по специальности, приобретенный в период обучения, или стаж работы на инженерно-технических должностях без квалификационной категории.

-программист: высшее профессиональное (техническое или инженерно-экономическое) образование без предъявления требований к стажу работы или среднее профессиональное (техническое или инженерно-экономическое) образование и стаж работы в должности техника I категории не менее 3 лет либо других должностях, замещаемых специалистами со средним профессиональным образованием, не менее 5 лет.)

3. Программист принимается на должность и освобождается от должности директором организации.

4. Программист должен знать:

  — руководящие и нормативные материалы, регламентирующие методы разработки алгоритмов и программ и использования вычислительной техники при обработке информации, основные принципы структурного программирования;

— виды программного обеспечения;

— технико-эксплуатационные характеристики, конструктивные особенности, назначение и режимы работы ЭВМ, правила ее технической эксплуатации;

— технологию автоматической обработки информации;

— виды технических носителей информации;

— методы классификации и кодирования информации;

— формализованные языки программирования;

— действующие стандарты, системы счислений, шифров и кодов;

— порядок оформления технической документации;

— передовой отечественный и зарубежный опыт программирования и использования вычислительной техники;

— основы экономики, организации производства, труда и управления;

— основы трудового законодательства;

— правила внутреннего трудового распорядка;

— правила и нормы охраны труда, техники безопасности, производственной санитарии и противопожарной защиты.

5. В своей деятельности программист руководствуется:

  — законодательством РФ,

— Уставом организации,

— приказами и распоряжениями работников, которым он подчинен согласно настоящей инструкции,

— настоящей должностной инструкцией,

— Правилами внутреннего трудового распорядка организации.

6. Программист подчиняется непосредственно __________ (указать должность того работнику, которому подчиняется).

7. На время отсутствия программиста (командировка, отпуск, болезнь, пр.) его обязанности исполняет лицо, назначенное директором организации в установленном порядке, которое приобретает соответствующие права, обязанности и несет ответственность за исполнение возложенных на него обязанностей.



2. Должностные обязанности программиста
Программист:

  1. На основе анализа математических моделей и алгоритмов решения экономических и других задач разрабатывает программы, обеспечивающие возможность выполнения алгоритма и соответственно поставленной задачи средствами вычислительной техники, проводит их тестирование и отладку.

2. Разрабатывает технологию решения задачи по всем этапам обработки информации.

3. Осуществляет выбор языка программирования для описания алгоритмов и структур данных.

4. Определяет информацию, подлежащую обработке средствами вычислительной техники, ее объемы, структуру, макеты и схемы ввода, обработки, хранения и вывода, методы ее контроля.

5. Выполняет работу по подготовке программ к отладке и проводит отладку.

6. Определяет объем и содержание данных контрольных примеров, обеспечивающих наиболее полную проверку соответствия программ их функциональному назначению.

7. Осуществляет запуск отлаженных программ и ввод исходных данных, определяемых условиями поставленных задач.

8. Проводит корректировку разработанной программы на основе анализа выходных данных. Разрабатывает инструкции по работе с программами, оформляет необходимую техническую документацию.

9. Определяет возможность использования готовых программных продуктов.

10. Осуществляет сопровождение внедренных программ и программных средств.

11. Разрабатывает и внедряет системы автоматической проверки правильности программ, типовые и стандартные программные средства, составляет технологию обработки информации.

12. Выполняет работу по унификации и типизации вычислительных процессов.

13. Принимает участие в создании каталогов и картотек стандартных программ, в разработке форм документов, подлежащих машинной обработке, в проектировании программ, позволяющих расширить область применения вычислительной техники.

14. Соблюдает Правила внутреннего трудового распорядка и иные локальные нормативные акты организации.

15. Соблюдает внутренние правила и нормы сбыта, техники безопасности, производственной санитарии и противопожарной защиты.

16. Обеспечивает соблюдение чистоты и порядка на своем рабочем месте,

  17. Выполняет в рамках трудового договора распоряжения работников, которым он подчинен согласно настоящей инструкции.



3. Права программиста
Программист имеет право:

  1. Вносить на рассмотрение директора организации предложения:

  — по совершенствованию работы связанной с предусмотренными настоящей инструкцией обязанностями,

— о поощрении подчиненных ему отличившихся работников,

— о привлечении к материальной и дисциплинарной ответственности подчиненных ему работников, нарушивших производственную и трудовую дисциплину.

2. Запрашивать от структурных подразделений и работников организации информацию, необходимую ему для выполнения своих должностных обязанностей.

3. Знакомиться с документами, определяющими его права и обязанности по занимаемой должности, критерии оценки качества исполнения должностных обязанностей.

4. Знакомиться с проектами решений руководства организации, касающимися его деятельности.

5. Требовать от руководства организации оказания содействия, в том числе обеспечения организационно-технических условий и оформления установленных документов, необходимых для исполнения должностных обязанностей.

6. Иные права, установленные действующим трудовым законодательством.



4. Ответственность программиста
Программист несет ответственность в следующих случаях:

  1. За ненадлежащее исполнение или неисполнение своих должностных обязанностей, предусмотренных настоящей должностной инструкцией, — в пределах, установленных трудовым законодательством Российской Федерации.

2. За правонарушения, совершенные в процессе своей деятельности, — в пределах, установленных действующим административным, уголовным и гражданским законодательством Российской Федерации.

3. За причинение материального ущерба организации — в пределах, установленных действующим трудовым и гражданским законодательством Российской Федерации.



  Должностная инструкция программиста - образец 2021 года. Должностные обязанности программиста, права программиста, ответственность программиста.
`
