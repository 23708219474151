import styled from 'styled-components'

import { defaultTransition, media } from '../../styles'

export const Layout = styled.div`
  margin: 40px 60px;

  ${defaultTransition('margin')};

  ${media.xl`
      margin: 25px 40px;
  `}

  ${media.md`
      margin: 15px;
  `}
`

export const ContentLayout = styled.div`
  background-color: ${({ theme }) => theme.palette.body};
  box-shadow: ${({ theme }) => theme.shadow};
  padding: 20px 30px;
  font-size: 14px;
  line-height: 1.5;

  ${defaultTransition('padding')};

  ${media.xl`
      padding: 15px 20px;
      padding: 15px 20px;
  `}
`
