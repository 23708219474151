import React from 'react'
import styled from 'styled-components'

import { defaultTransition, media } from '../../styles'

export const Icon = styled(({ component, ...props }) =>
  React.cloneElement(component, props)
)`
  width: 50px;
  height: 50px;

  ${defaultTransition('width', 'height')}

  ${media.lg`
    width: 38px;
    height: 38px;
  `}

  ${media.sm`
    width: 24px;
    height: 24px;
  `}
`
