import React, { memo, PropsWithChildren } from 'react'

import { IconButton, Wrapper } from './tools-item.styled'

interface IToolsItemProps {
  onClick: () => void
  disabled?: boolean
}

export const ToolsItem = memo(
  ({
    children,
    onClick,
    disabled = false,
    ...props
  }: PropsWithChildren<IToolsItemProps>) => (
    <Wrapper {...props}>
      <IconButton onClick={onClick} disabled={disabled}>
        {children}
      </IconButton>
    </Wrapper>
  )
)

ToolsItem.displayName = 'ToolsItem'
