import styled from 'styled-components'

import { media, textInputStyles } from '../../styles'
import { SendButton } from '../common'

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.body};
  box-shadow: ${({ theme }) => theme.shadow};
  height: calc(100vh - 170px);
  width: 100%;

  ${media.lg`
      height: calc(100vh - 125px);
  `}

  ${media.md`
      height: calc(100vh - 100px);
  `}
  
  ${media.sm`
      height: calc(100vh - 80px);
  `}
`

export const UserInput = styled.textarea.attrs({
  spellcheck: 'true',
  cols: 50,
  wrap: 'true',
})`
  width: 100%;
  flex-grow: 1;
  height: 100%;
  resize: none;
  ${textInputStyles};

  ${media.md`
    font-size: 14px;
  `}

  ${media.sm`
    padding: 5px;
    font-size: 13px;
  `}
`

export const InputRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 20px;
  height: 80px;

  ${media.md`
    height: 60px;
  `}

  ${media.sm`
    height: 40px;
    padding: 0 10px;
  `}
`

export const Button = styled(SendButton.type)`
  height: 100%;

  ${media.md`
    font-size: 14px;
  `}

  ${media.sm`
    font-size: 13px;
    width: auto;
    margin-top: 0;
    margin-left: 10px;
    padding: 5px 10px;
  `}
`

export const ButtonText = styled.span`
  margin-bottom: 5px;
`

export const InnerButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`
