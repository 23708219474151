import React from 'react'
import styled from 'styled-components'

import { defaultTransition, media } from '../../styles'
import { ToolsItem } from './tools-item'

export const Wrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 90px;
  background-color: ${({ theme }) => theme.palette.header};
  padding: 0 45px;
  box-shadow: ${({ theme }) => theme.shadow};
  ${defaultTransition('padding', 'height')}

  ${media.lg`
    padding: 0 25px;
    height: 75px;
  `}

  ${media.md`
    padding: 0 18px;
    height: 60px;
  `}

  ${media.sm`
    padding: 0 10px;
    height: 40px;
  `}
`

export const Icon = styled(({ component, ...props }) =>
  React.cloneElement(component, props)
)`
  width: 25px;
  height: 25px;

  ${defaultTransition('width', 'height')}

  ${media.md`
    width: 20px;
    height: 20px;
  `}
  
  ${media.sm`
    width: 16px;
    height: 16px;
  `}
`

export const NavigationMobileButton = styled(ToolsItem.type)`
  display: none;

  ${media.sm`
    display: flex;
  `}
`
