import { createContext } from 'react'
import { IUser } from '../types'

interface IUserContext {
  id: string
  user: IUser | undefined
}

const defaultContext: IUserContext = {
  id: '',
  user: undefined,
}

export const UserContext = createContext(defaultContext)

UserContext.displayName = 'UserContext'

export function getId() {
  const urlParams = new URLSearchParams(window.location.search)

  let storageId = ''

  try {
    storageId = localStorage.getItem('id') || ''
  } catch (e) {
    console.error(e)
  }

  return urlParams.get('id') || storageId
}

export function setId(id: string) {
  try {
    localStorage.setItem('id', id)
  } catch (e) {
    console.error(e)
  }
}

export function removeId() {
  try {
    localStorage.removeItem('id')
  } catch (e) {
    console.error(e)
  }
}
