import React, {
  ChangeEvent,
  KeyboardEvent,
  memo,
  useCallback,
  useState,
} from 'react'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'

import { H1 } from '../typography'
import { Container, Input, Row, Wrapper } from './login-input.styled'
import { SendButton } from '../common'

export const LoginInput = memo(() => {
  const { t } = useTranslation()
  const [id, setId] = useState('')

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const reformattedValue = value.toLowerCase().replace(/\s/, '')

    setId(reformattedValue)
  }, [])

  const handleLogin = useCallback(() => {
    navigate(`/?id=${id}`)
  }, [id])

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter' && !!id) {
        handleLogin()
      }
    },
    [handleLogin, id]
  )

  return (
    <Wrapper>
      <Container>
        <H1>{t('notLoggedIn.title')}</H1>
        <Row>
          <Input
            value={id}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={t('notLoggedIn.placeholder')}
          />
          <SendButton disabled={!id} onClick={handleLogin}>
            {t('notLoggedIn.button')}
          </SendButton>
        </Row>
      </Container>
    </Wrapper>
  )
})

LoginInput.displayName = 'LoginInput'
