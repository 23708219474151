import styled, { css } from 'styled-components'
import { GoSearch } from 'react-icons/all'

import { defaultTransition, hover, media } from '../../../styles'

export const SearchIcon = styled(GoSearch)`
  min-width: 25px;
  max-width: 25px;
  width: 25px;
  min-height: 25px;
  max-height: 25px;
  height: 25px;
  color: ${({ theme }) => theme.palette.grey};

  ${defaultTransition(
    'min-width',
    'min-height',
    'height',
    'width',
    'max-width',
    'max-height'
  )}

  ${media.md`
    min-width: 20px;
    max-width: 20px;
    width: 20px;
    min-height: 20px;
    max-height: 20px;
    height: 20px;
  `}

  ${media.sm`
    min-width: 16px;
    max-width: 16px;
    width: 16px;
    min-height: 16px;
    max-height: 16px;
    height: 16px;
  `}
`

export const Input = styled.input.attrs({
  type: 'text',
})`
  font-family: inherit;
  font-size: 20px;
  margin-left: 25px;
  height: 100%;
  padding: 0;
  width: 0;
  border: none;
  border-bottom: 3px solid;
  background-color: ${({ theme }) => theme.palette.header};
  border-bottom-color: rgba(0, 0, 0, 0.0005);

  &:disabled {
    background-color: ${({ theme }) => theme.palette.header};
  }

  ${defaultTransition(
    'border-bottom-color',
    'width',
    'font-size',
    'margin-left'
  )}

  ${hover`
    outline: none;
    border-bottom-color: ${({ theme }: any) => theme.palette.blue};
  `}
  
  &:focus {
    width: 100%;
  }

  ${media.lg`
    font-size: 18px;
  `}

  ${media.md`
    font-size: 16px;
    margin-left: 20px;
  `}

  ${media.sm`
    font-size: 14px;
    width: 100%;
    margin-left: 10px;
  `}
`

export const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: auto;
  padding-right: 0;
  flex-grow: initial;

  ${defaultTransition('padding-right', 'flex-grow')}

  ${hover`
    ${Input} {
      border-bottom-color: ${({ theme }: any) => theme.palette.blue};
    }
  `}

  ${({ isSearchFocused }: { isSearchFocused: boolean }) =>
    isSearchFocused &&
    css`
      flex-grow: 1;
    `}

  ${media.sm`
     flex-grow: 1;
     padding-right: 10px;
  `}
`
