import { Icon } from '../icon'
import { CgFeed, GiLaptop, IoIosContacts, SiReadthedocs } from 'react-icons/all'
import React from 'react'

export const navigationItems = [
  {
    name: 'region.navigation.dashboard',
    to: '/app/dashboard',
    icon: <Icon component={<GiLaptop />} />,
  },
  {
    name: 'region.navigation.job',
    to: '/app/job',
    icon: <Icon component={<CgFeed />} />,
  },
  {
    name: 'region.navigation.docs',
    to: '/app/docs',
    icon: <Icon component={<SiReadthedocs />} />,
  },
  {
    name: 'region.navigation.connections',
    to: '/app/connections',
    icon: <Icon component={<IoIosContacts />} />,
  },
]
